import "./App.css";
import { Mainpage } from "./components/MainPage/Mainpage";

function App() {
  return (
    <div className = "App">
      {/* <NavBar items={["Home", "About", "Services", "Contact"]} /> */}
      <Mainpage />
      {/* <Attributions /> */}
    </div>
  );
}

export default App;
